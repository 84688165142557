@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

div.formContainer {
  display: flex;
  @media (--viewportMedium) {
    width: 700px;
  }
}

.formContainer :global(#mc_embed_shell) {
  width: 100%;
}

.formContainer h2 {
  text-align: center;
  margin: auto;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-size: 26px;
    margin: 0 auto 15px;
    line-height: 30px;
  }
}

p.description {
  margin: auto;
  text-align: center;
  line-height: 1.4;
  max-width: 500px;
}

.formContainer :global(.mc-field-group) {
  display: flex;
  border-radius: 25px;
  background-color: #fff;
  overflow: hidden;
  border: 3px solid var(--colorWhite);
  max-width: 400px;
  margin: 24px auto 0;
}

.formContainer :global(#mce-EMAIL) {
  border-radius: 0px;
  border: 0px solid transparent;
  padding: 7px 20px;
}

.formContainer :global(#mc-embedded-subscribe) {
  border-radius: 0px;
  border: 0px solid transparent;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  padding: 7px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}
